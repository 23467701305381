import {
  ASSESSMENT_TYPES,
  ASSESSMENT_TYPES_WITH_SITE_COST,
} from "./table.utils";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DragIndicator, RemoveCircle } from "@material-ui/icons";
import { FIELD_TYPES, REQUIRED_FIELDS_ERROR_MESSAGES } from "../constants";
import {
  checkNumberLength,
  isValidNumberStringValues,
} from "../../utils/helpers";

import { Draggable } from "react-beautiful-dnd";
import FuzzySearchInput from "../../../../app/components/fuzzy.search.input";
import React, { useState } from "react";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";

let timeoutId = null;

/*
  This component is used to render a row in the table.
  It uses the material ui table cell component.
  It uses the material ui table row component.
  The parent component must pass the remove row function.
  The parent component must pass the on assessments change function.
  The parent component must pass the remove column width of the table.
  The parent component must pass the type column width of the table.
  The parent component must pass the value of the table.
  The parent component must pass the index of the table row.
  The parent component must pass the assessments of the table.
  The parent component must pass the assessment names of the table.
  The parent component must pass the column count of the table.
*/
export default function TableRow({
  sv,
  removeRow,
  onAssessmentsChange,
  value,
  index,
  assessmentNames,
  fieldType,
}) {
  const { specialSOEErrorMessages, setSpecialSOEErrorMessages } =
    useScheduleEvents();
  const [assessmentNameMappingValid, setAssessmentNameMappingValid] =
    React.useState(true);

  const commentTypeKey = React.useMemo(() => {
    if (fieldType === FIELD_TYPES.VISIT) return "commentsAssessment";
    if (fieldType === FIELD_TYPES.COST) return "commentsCost";
    if (fieldType === FIELD_TYPES.MEDICAL) return "commentsClinicalStrategy";
    if (fieldType === FIELD_TYPES.DATA_MANAGEMENT)
      return "commentsDataManagement";
    return "";
  }, [fieldType]);

  const [commentTypeKeyState, setCommentTypeKeyState] = useState(
    sv[commentTypeKey] || "",
  );

  const [assessmentNameState, setAssessmentNameState] = useState(
    sv.assessmentName ?? "",
  );

  const [assessmentCostState, setAssessmentCostState] = useState(
    sv.assessmentCost ?? "",
  );

  const updateSpecialSOEErrorMessages = (isValid, key) => {
    if (isValid) {
      if (specialSOEErrorMessages[key]?.count > 1) {
        console.log(specialSOEErrorMessages[key]?.count, {
          assessmentNameType: {
            message: REQUIRED_FIELDS_ERROR_MESSAGES.assessmentNameType,
            count: specialSOEErrorMessages[key]?.count - 1,
          },
        });
        setSpecialSOEErrorMessages({
          assessmentNameType: {
            message: REQUIRED_FIELDS_ERROR_MESSAGES.assessmentNameType,
            count: specialSOEErrorMessages[key]?.count - 1,
          },
        });
      } else setSpecialSOEErrorMessages({});
    } else {
      setSpecialSOEErrorMessages({
        assessmentNameType: {
          message: REQUIRED_FIELDS_ERROR_MESSAGES.assessmentNameType,
          count: (specialSOEErrorMessages[key]?.count || 0) + 1,
        },
      });
    }
  };

  const debounce = (func, wait, immediate) => {
    return function () {
      const context = this,
        args = arguments;

      const later = function () {
        timeoutId = null;
        if (!immediate) func.apply(context, args);
      };

      const callNow = immediate && !timeoutId;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  };

  const debouncedOnAssessmentsChange = debounce(onAssessmentsChange, 500);

  const renderRow = (dndProps) => {
    const { dragHandleProps, ...remainingDnDProps } = dndProps;

    return (
      <Row {...remainingDnDProps}>
        <Cell width={value === 0 ? "12%" : "40%"}>
          {value === 0 ? (
            <>
              <DragHandleContainer {...dragHandleProps}>
                <DragIndicator />
              </DragHandleContainer>
              <SelectContainer>
                <FormControl
                  style={{ width: "100%" }}
                  size="small"
                  variant="outlined"
                >
                  <Select
                    id="assessment-type"
                    value={sv.assessmentType}
                    onChange={(e) => {
                      onAssessmentsChange(
                        "assessmentType",
                        e.target.value,
                        sv.scheduleOfEventsAssessmentId || sv.key,
                        "string",
                      );
                    }}
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={sv.assessmentType}
                  >
                    {ASSESSMENT_TYPES.map(({ name, value }) => (
                      <MenuItem
                        value={value}
                        key={value}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </SelectContainer>
            </>
          ) : (
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                padding: "5px",
              }}
              title={sv.assessmentType}
            >
              {sv.assessmentType}
            </Typography>
          )}
        </Cell>
        <Cell width={value === 0 ? "18%" : "40%"}>
          {value === 0 ? (
            <TextField
              value={assessmentNameState}
              onChange={(e) => {
                setAssessmentNameState(e.target.value);
                debouncedOnAssessmentsChange(
                  "assessmentName",
                  e.target.value,
                  sv.scheduleOfEventsAssessmentId || sv.key,
                );
              }}
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              inputProps={{
                maxLength: 100,
              }}
            />
          ) : (
            <Typography variant="body2">{sv.assessmentName}</Typography>
          )}
        </Cell>
        {value === 0 && (
          <Cell width="18%">
            <FuzzySearchInput
              key={`${sv.assessmentType}${assessmentNameMappingValid}`}
              options={assessmentNames}
              style={{ width: "100%" }}
              defaultValue={sv.assessmentNameMapping}
              onBlur={() => {
                if (sv.assessmentNameMapping) {
                  let isValid = true;
                  const nameExists = !!assessmentNames.find(
                    (name) => name === sv.assessmentNameMapping,
                  );

                  isValid = nameExists;
                  if (!isValid && sv.assessmentNameMapping) {
                    updateSpecialSOEErrorMessages(
                      isValid,
                      "assessmentNameMapping",
                    );
                    setAssessmentNameMappingValid(isValid);
                  } else if (!sv.assessmentNameMapping) {
                    updateSpecialSOEErrorMessages(
                      true,
                      "assessmentNameMapping",
                    );
                    setAssessmentNameMappingValid(true);
                  } else {
                    setAssessmentNameMappingValid(isValid);
                  }
                }
              }}
              onChange={(value) => {
                onAssessmentsChange(
                  "assessmentNameMapping",
                  value ?? "",
                  sv.scheduleOfEventsAssessmentId || sv.key,
                  "string",
                );
                if (
                  value === "" ||
                  assessmentNames.find((name) => name === value)
                ) {
                  setAssessmentNameMappingValid(true);
                  updateSpecialSOEErrorMessages(true, "assessmentNameMapping");
                }
              }}
              message="Select assessment name from the list."
              error={!assessmentNameMappingValid}
            />
          </Cell>
        )}
        <Cell width={value === 0 ? "6%" : "20%"}>
          <TextField
            style={{ width: "100%" }}
            value={assessmentCostState}
            onChange={(e) => {
              if (
                !isValidNumberStringValues(e.target.value) ||
                !checkNumberLength(e.target.value, 8, 2)
              )
                return;

              setAssessmentCostState(e.target.value);
              debouncedOnAssessmentsChange(
                "assessmentCost",
                e.target.value,
                sv.scheduleOfEventsAssessmentId || sv.key,
              );
            }}
            variant="outlined"
            size="small"
          />
        </Cell>

        {value === 0 && (
          <>
            <Cell width="6%">
              <Checkbox
                disabled={ASSESSMENT_TYPES_WITH_SITE_COST.includes(
                  sv.assessmentType,
                )}
                checked={sv.overhead ?? false}
                onChange={(e) => {
                  onAssessmentsChange(
                    "overhead",
                    e.target.checked ? 1 : 0,
                    sv.scheduleOfEventsAssessmentId || sv.key,
                  );
                }}
                name="overhead"
              />
            </Cell>
            <Cell width="6%">
              <Checkbox
                disabled={ASSESSMENT_TYPES_WITH_SITE_COST.includes(
                  sv.assessmentType,
                )}
                checked={sv.exclude ?? false}
                onChange={(e) => {
                  onAssessmentsChange(
                    "exclude",
                    e.target.checked ? 1 : 0,
                    sv.scheduleOfEventsAssessmentId || sv.key,
                  );
                }}
                name="exclude"
              />
            </Cell>
            <Cell width="6%">
              <Checkbox
                disabled={ASSESSMENT_TYPES_WITH_SITE_COST.includes(
                  sv.assessmentType,
                )}
                checked={sv.ipAdministered ?? false}
                onChange={(e) => {
                  onAssessmentsChange(
                    "ipAdministered",
                    e.target.checked ? 1 : 0,
                    sv.scheduleOfEventsAssessmentId || sv.key,
                  );
                }}
                name="ipAdministered"
              />
            </Cell>
            <Cell width="26%">
              {value === 0 ? (
                <TextField
                  key={commentTypeKey}
                  value={commentTypeKeyState}
                  onChange={(e) => {
                    setCommentTypeKeyState(e.target.value);
                    //Robert
                    debouncedOnAssessmentsChange(
                      commentTypeKey,
                      e.target.value,
                      sv.scheduleOfEventsAssessmentId || sv.key,
                    );
                  }}
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  inputProps={{
                    maxLength: 250,
                  }}
                />
              ) : (
                <Typography variant="body2">{sv.commentTypeKey}</Typography>
              )}
            </Cell>
            <Cell width="2%">
              <RemoveButtonContainer
                onClick={() =>
                  removeRow(sv.scheduleOfEventsAssessmentId || sv.key)
                }
              >
                <RemoveCircle style={{ fontSize: "1rem" }} />
              </RemoveButtonContainer>
            </Cell>
          </>
        )}
      </Row>
    );
  };

  return (
    <>
      {value === 0 ? (
        <Draggable
          draggableId={(sv.key || sv.scheduleOfEventsAssessmentId).toString()}
          index={index}
        >
          {(provided) =>
            renderRow({
              dragHandleProps: provided.dragHandleProps,
              ...provided.draggableProps,
              ref: provided.innerRef,
            })
          }
        </Draggable>
      ) : (
        renderRow({})
      )}
    </>
  );
}

const DragHandleContainer = styled.div`
  color: #555;
  width: 2.5rem;
`;

const RemoveButtonContainer = styled.div`
  color: red;
  cursor: pointer;
  background: white;
  padding: 1px;
  border-radius: 50%;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 51px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;

const SelectContainer = styled.div`
  flex: 1;
  min-width: 0;
  padding: 5px;
  overflow: hidden;
`;
