import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";

const Header = ({ text, onClose }) => {
  const classes = useStyles();
  return (
    <Container>
      <Typography className={classes.root}>
        <strong>{text}</strong>
      </Typography>
      <IconButton onClick={onClose}>
        <ClearIcon className={classes.icon} />
      </IconButton>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  background: var(--Primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3em 0;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    marginLeft: "1em",
  },
  icon: {
    opacity: 0.6,
    color: "var(--White)",
  },
}));
