import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DragIndicator, RemoveCircle } from "@material-ui/icons";
import {
  checkNumberLength,
  isValidNumberStringValues,
} from "../../utils/helpers";
import { Draggable } from "react-beautiful-dnd";
import { FIELD_TYPES } from "../constants";
import React, { useState } from "react";
import VisitInputField from "./visit.input.field";
import styled from "styled-components";
import {
  VISIT_CATEGORY_TYPES,
  VISIT_VARIANCE_TYPE_OPTIONS,
} from "../constants";

// const VISIT_TYPE_MAX_WIDTH = {
//   [FIELD_TYPES.VISIT]: "100%",
//   [FIELD_TYPES.COST]: "100%",
//   [FIELD_TYPES.MEDICAL]: "100%",
//   [FIELD_TYPES.DATA_MANAGEMENT]: "100%",
// };

/*
  This component is responsible for rendering a row in the table of visits for a schedule of events.
  It uses the material ui table cell component.
  It is responsible for rendering the cells for each column in the table.
  It is also responsible for rendering the drag handle and remove button.
  It is also responsible for rendering the input fields for each cell.
  It is also responsible for handling the change events for each input field.
  It is also responsible for handling the remove row event.
  It is also responsible for handling the drag and drop events.
*/
let timeoutId = null;

export default function TableRow({
  sv,
  removeRow,
  onVisitsChange,
  value,
  index,
  fieldType,
}) {
  const commentTypeKey = React.useMemo(() => {
    if (fieldType === FIELD_TYPES.VISIT) return "commentsVisit";
    if (fieldType === FIELD_TYPES.COST) return "commentsCost";
    if (fieldType === FIELD_TYPES.MEDICAL) return "commentsClinicalStrategy";
    if (fieldType === FIELD_TYPES.DATA_MANAGEMENT)
      return "commentsDataManagement";
    return "";
  }, [fieldType]);

  const [commentTypeKeyState, setCommentTypeKeyState] = useState(
    sv[commentTypeKey] || "",
  );
  const [visitVarianceState, setVisitVarianceState] = useState(
    sv.visitVariance || "",
  );
  const [visitStudyDayState, setVisitStudyDayState] = useState(
    sv.visitStudyDay || 0,
  );

  const debounce = (func, wait, immediate) => {
    return function () {
      const context = this,
        args = arguments;

      const later = function () {
        timeoutId = null;
        if (!immediate) func.apply(context, args);
      };

      const callNow = immediate && !timeoutId;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  };

  const debouncedOnVisitsChange = debounce(onVisitsChange, 500);

  const renderRow = (dndProps) => {
    const { dragHandleProps, ...remainingDnDProps } = dndProps;
    return (
      <Row {...remainingDnDProps}>
        <Cell width={value === 0 ? "12%" : "25%"}>
          {value === 0 ? (
            <>
              <DragHandleContainer {...dragHandleProps}>
                <DragIndicator />
              </DragHandleContainer>
              <SelectContainer>
                <FormControl
                  style={{ width: "100%" }}
                  size="small"
                  variant="outlined"
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    select
                    id="visit-category"
                    value={sv.visitCategory}
                    onChange={(e) => {
                      onVisitsChange(
                        "visitCategory",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key,
                        "string",
                      );
                    }}
                    size="small"
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={sv.visitCategory}
                  >
                    {VISIT_CATEGORY_TYPES.map((vc) => (
                      <MenuItem
                        value={vc}
                        key={vc}
                      >
                        {vc}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </SelectContainer>
            </>
          ) : (
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                padding: "5px",
              }}
              title={sv.visitCategory}
            >
              {sv.visitCategory}
            </Typography>
          )}
        </Cell>
        <Cell width={value === 0 ? "18%" : "25%"}>
          {value === 0 ? (
            <VisitInputField
              onVisitsChange={onVisitsChange}
              sv={sv}
            />
          ) : (
            <Typography variant="body2">{sv.visitName}</Typography>
          )}
        </Cell>
        <Cell width={value === 0 ? "6%" : "10%"}>
          {value === 0 ? (
            <TextField
              style={{ width: "100%" }}
              value={visitStudyDayState}
              onChange={(e) => {
                if (
                  !isValidNumberStringValues(e.target.value) ||
                  !checkNumberLength(e.target.value, 4, 0, 9999)
                )
                  return;

                setVisitStudyDayState(e.target.value);
                debouncedOnVisitsChange(
                  "visitStudyDay",
                  e.target.value,
                  sv.scheduleOfEventsVisitId || sv.key,
                );
              }}
              variant="outlined"
              size="small"
              disabled={sv.visitCategory === "Unscheduled"}
            />
          ) : (
            <Typography variant="body2">{sv.visitStudyDay}</Typography>
          )}
        </Cell>

        <Cell width={value === 0 ? "6%" : "10%"}>
          {value === 0 ? (
            <FormControl
              style={{ width: "100%" }}
              size="small"
              variant="outlined"
            >
              <Select
                id="visit-variance-type"
                value={sv.visitVarianceType}
                onChange={(e) => {
                  onVisitsChange(
                    "visitVarianceType",
                    e.target.value,
                    sv.scheduleOfEventsVisitId || sv.key,
                    "string",
                  );
                }}
              >
                {VISIT_VARIANCE_TYPE_OPTIONS.map((vvt) => (
                  <MenuItem value={vvt.value}>{vvt.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="body2">{sv.visitVarianceType}</Typography>
          )}
        </Cell>
        <Cell width={value === 0 ? "6%" : "10%"}>
          {value === 0 ? (
            <TextField
              style={{ width: "100%" }}
              value={visitVarianceState}
              onChange={(e) => {
                if (
                  !isValidNumberStringValues(e.target.value) ||
                  !checkNumberLength(e.target.value, 5, 0, 9999)
                )
                  return;

                setVisitVarianceState(e.target.value);
                debouncedOnVisitsChange(
                  "visitVariance",
                  e.target.value,
                  sv.scheduleOfEventsVisitId || sv.key,
                );
              }}
              variant="outlined"
              size="small"
            />
          ) : (
            <Typography variant="body2">{sv.visitVariance}</Typography>
          )}
        </Cell>

        {(fieldType === FIELD_TYPES.COST || value !== 0) && (
          <>
            <Cell width={value === 0 ? "6%" : "10%"}>
              <TextField
                style={{ width: "100%" }}
                value={sv.visitOverheadPct ?? ""}
                onChange={(e) => {
                  if (
                    !isValidNumberStringValues(e.target.value) ||
                    !checkNumberLength(e.target.value, 3, 0, 100)
                  )
                    return;

                  onVisitsChange(
                    "visitOverheadPct",
                    e.target.value,
                    sv.scheduleOfEventsVisitId || sv.key,
                  );
                }}
                variant="outlined"
                size="small"
              />
            </Cell>
            <Cell width={value === 0 ? "6%" : "10%"}>
              <Typography variant="body2">{sv.visitCost}</Typography>
            </Cell>
          </>
        )}
        {value === 0 && (
          <>
            {/* Medical Informatics Fields */}
            {fieldType === FIELD_TYPES.MEDICAL && (
              <>
                <Cell width="6%">
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.miEstSDRSDV ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "miEstSDRSDV",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key,
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width="6%">
                  <Checkbox
                    checked={sv.miSampleVisit}
                    onChange={(e) => {
                      onVisitsChange(
                        "miSampleVisit",
                        e.target.checked,
                        sv.scheduleOfEventsVisitId || sv.key,
                        "checkbox",
                      );
                    }}
                    name="detailsMonthly"
                  />
                </Cell>
                <Cell width="6%">
                  <Typography variant="body2">
                    {sv.miSuggestedOnSiteSDRSDV}
                  </Typography>
                </Cell>
                <Cell width="6%">
                  <Typography variant="body2">
                    {sv.miSuggestedCDAReview}
                  </Typography>
                </Cell>
              </>
            )}

            {/* Data Management Fields */}
            {fieldType === FIELD_TYPES.DATA_MANAGEMENT && (
              <>
                <Cell width="6%">
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmUniqueCRFs ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmUniqueCRFs",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key,
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
                <Cell width="6%">
                  <TextField
                    style={{ width: "100%" }}
                    value={sv.dmNonUniqueCRFs ?? ""}
                    onChange={(e) => {
                      if (
                        !isValidNumberStringValues(e.target.value) ||
                        !checkNumberLength(e.target.value, 6, 2)
                      )
                        return;
                      onVisitsChange(
                        "dmNonUniqueCRFs",
                        e.target.value,
                        sv.scheduleOfEventsVisitId || sv.key,
                      );
                    }}
                    variant="outlined"
                    size="small"
                  />
                </Cell>
              </>
            )}
          </>
        )}
        {value === 0 && (
          <Cell
            width={() => {
              switch (commentTypeKey) {
                case "commentsVisit":
                  return "50%";
                case "commentsCost":
                  return "38%";
                case "commentsClinicalStrategy":
                  return "26%";
                case "commentsDataManagement":
                  return "38%";
                default:
                  return "0%"; // or any default width you prefer
              }
            }}
          >
            <TextField
              key={commentTypeKey}
              value={commentTypeKeyState || ""}
              onChange={(e) => {
                setCommentTypeKeyState(e.target.value);
                debouncedOnVisitsChange(
                  commentTypeKey,
                  e.target.value,
                  sv.scheduleOfEventsVisitId || sv.key,
                );
              }}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 250,
              }}
              style={{ width: "100%" }}
            />
          </Cell>
        )}
        {value === 0 ? (
          <Cell width="2%">
            <RemoveButtonContainer
              onClick={() => removeRow(sv.scheduleOfEventsVisitId || sv.key)}
            >
              <RemoveCircle style={{ fontSize: "1rem" }} />
            </RemoveButtonContainer>
          </Cell>
        ) : (
          ""
        )}
      </Row>
    );
  };

  return (
    <>
      {value === 0 ? (
        <Draggable
          draggableId={(sv.key || sv.scheduleOfEventsVisitId).toString()}
          index={index}
        >
          {(provided) =>
            renderRow({
              dragHandleProps: provided.dragHandleProps,
              ...provided.draggableProps,
              ref: provided.innerRef,
            })
          }
        </Draggable>
      ) : (
        renderRow({})
      )}
    </>
  );
}

const DragHandleContainer = styled.div`
  color: #555;
  width: 2.5rem;
`;

const RemoveButtonContainer = styled.div`
  color: red;
  cursor: pointer;
  background: white;
  padding: 1px;
  border-radius: 50%;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  min-height: 50px;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding-inline: 5px;
  text-align: center;
  border-left: ${(p) => (p.leftBorder ? "1px solid #ccc" : "none")};
  &:last-child {
    border: ${(p) => (p.rightBorder ? "1px solid #ccc" : "none")};
  }
  border: ${(p) => (p.fullBorder ? "1px solid #ccc" : "")};
`;
const SelectContainer = styled.div`
  flex: 1;
  min-width: 0;
  padding: 5px;
  overflow: hidden;
`;
