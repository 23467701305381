import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Field, getIn } from "formik";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SeparateContainer from "../../../../app/components/separate.Container";

const HeaderCell = ({ children, variant = null }) => (
  <Cell variant={variant}>
    <Typography align="center">
      {variant === "header" ? <strong>{children}</strong> : children}
    </Typography>
  </Cell>
);

const DataCell = ({ children, align = "center" }) => (
  <Cell>
    <Typography align={align}>{children}</Typography>
  </Cell>
);

const NumberField = ({ name, error, classes }) => (
  <Field name={name}>
    {({ field }) => (
      <TextField
        {...field}
        classes={classes}
        variant="outlined"
        type="number"
        error={error}
      />
    )}
  </Field>
);

export default function SFDOPatients({ errors, touched, values, setValues }) {
  const screenedPatients = getIn(values, "screenPatientsReModel");
  const enrolledPatients = getIn(values, "enrollPatientsReModel");
  const completedPatients = getIn(values, "completePatientsReModel");
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "80px",
    },
  }));

  useEffect(() => {
    let updates = {};
    if (screenedPatients && screenedPatients >= enrolledPatients) {
      updates["enrollPatientsRate"] = (
        (screenedPatients - enrolledPatients) /
        screenedPatients
      ).toFixed(2);
    }
    if (enrolledPatients && enrolledPatients >= completedPatients) {
      updates["completePatientsRate"] = (
        (enrolledPatients - completedPatients) /
        enrolledPatients
      ).toFixed(2);
    }
    setValues({
      ...values,
      ...updates,
    });
    // eslint-disable-next-line
  }, [screenedPatients, enrolledPatients, completedPatients]);

  const classes = useStyles();

  const fieldNames = [
    "screenPatientsReModel",
    "enrollPatientsReModel",
    "completePatientsReModel",
  ];

  return (
    <Container>
      <SeparateContainer>
        <TableContainer>
          <Table>
            <Row>
              <HeaderCell variant={"header"}></HeaderCell>
              <HeaderCell>Screened Patients</HeaderCell>
              <HeaderCell>Enrolled Patients</HeaderCell>
              <HeaderCell>Completed Patients</HeaderCell>
            </Row>
            <Row>
              <HeaderCell variant={"header"}>Planned</HeaderCell>
              <DataCell>{values?.screenPatientsPlanned}</DataCell>
              <DataCell>{values?.enrollPatientsPlanned}</DataCell>
              <DataCell>{values?.completePatientsPlanned}</DataCell>
            </Row>
            <Row>
              <HeaderCell variant="header">Re-model</HeaderCell>
              {fieldNames.map((name, index) => (
                <DataCell key={index}>
                  <NumberField
                    key={index}
                    name={name}
                    error={touched[name] && errors[name]}
                    classes={classes}
                  />
                </DataCell>
              ))}
            </Row>
            <Row>
              <HeaderCell variant={"header"}>Rate</HeaderCell>
              <DataCell></DataCell>
              <DataCell>{values?.enrollPatientsRate}</DataCell>
              <DataCell>{values?.completePatientsRate}</DataCell>
            </Row>
          </Table>
        </TableContainer>
      </SeparateContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 10px 20px 20px 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 19em;
`;

const Cell = styled.div`
  background: ${(p) => (p.variant === "header" ? "#f3f4f4" : "white")};
  height: 8em;
  align-items: center;
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid var(--Grey-4);
  padding: 0.5em;
  padding-left: 3em;
`;

const Table = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid var(--Grey-4);
  border-bottom: none;
  border-radius: 4px;
`;
