import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStudyPlan } from "../../../contexts/study.context";
import API from "../../../utils/api";
import { Modal, SpecialTextField } from "../..";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import ToolTip from "../../tooltip";
import PrimaryButton from "../../buttons/primary";
import SecondaryButton from "../../buttons/secondary";

const timeframes = [
  { value: "Super Short", key: "SuperShort", label: "Super Short" },
  { value: "Short", key: "Short", label: "Short" },
  { value: "Medium", key: "Medium", label: "Medium" },
  { value: "Long", key: "Long", label: "Long" },
];

const siteIdData = [
  {
    value: "Small",
    label: "Small",
    days: "timeSiteIdSmallDays",
    sites: "1-2 sites",
  },
  {
    value: "Medium",
    label: "Medium",
    days: "timeSiteIdMediumDays",
    sites: "3-10 sites",
  },
  {
    value: "Large",
    label: "Large",
    days: "timeSiteIdLargeDays",
    sites: "10+ sites",
  },
];

const EditCountryModal = ({
  countryId,
  siteGroup,
  currentEffort,
  currentTimeframe,
  studyPlanType,
  open = false,
  onClose,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [manualEffort, setManualEffort] = useState(currentEffort);
  const {
    updateCountries,
    saveLoading,
    setIsCountryUpdate,
    isCountryUpdate,
    studyPlan,
  } = useStudyPlan();
  const { parameters, siteGroupId } = siteGroup;

  const [sites_to_activate] = parameters;

  useEffect(() => {
    API.getSiteGroups()
      .then((res) => {
        const index = res.data.map((site) => site.countryId).indexOf(countryId);
        setSelectedCountry(res.data[index]);
      })
      .catch((err) => console.error(err));

    let update = { timeCountryStartUpSize: currentTimeframe };
    updateCountries(countryId, siteGroupId, update);
    // eslint-disable-next-line
  }, []);

  const isSiteNumberEmpty = () => {
    return (
      !sites_to_activate.lowerBound ||
      (studyPlanType === 1 && !sites_to_activate.upperBound)
    );
  };

  const handleSave = () => {
    const body = {
      studyPlanId: studyPlan.studyPlanId,
      siteGroupId: siteGroup.siteGroupId,
      NUM_SITES_ACTIVATED_LOWER: sites_to_activate.lowerBound,
      NUM_SITES_ACTIVATED_UPPER: sites_to_activate.upperBound,
      siteIdEffort: manualEffort,
      countryStartUpTimeFrame: currentTimeframe,
    };

    setIsCountryUpdate(false);
    API.postSiteGroup(body, studyPlan.studyId)
      .then((res) => {
        setIsCountryUpdate(false);
        onClose();
        // Force a page refresh after successful save
        window.location.reload(true);
      })
      .catch((error) => {
        console.error("Error saving plan:", error);
        // Handle error appropriately
        setIsCountryUpdate(true);
      });
  };

  const handleChange = (property, value) => {
    const update = { [property]: value };
    updateCountries(countryId, siteGroupId, update);
    setIsCountryUpdate(true);
  };

  const handleCancel = () => {
    const update = {
      timeSiteIdSize: currentEffort,
      timeCountryStartUpSize: currentTimeframe,
    };
    updateCountries(countryId, siteGroupId, update);
    onClose();
  };

  const getEffortFromSites = (value) => {
    const numericValue = Number(value);
    if (numericValue >= 1 && numericValue <= 2) {
      return "Small";
    } else if (numericValue >= 3 && numericValue <= 10) {
      return "Medium";
    } else if (numericValue > 10) {
      return "Large";
    }
    return null;
  };

  const handleUpdateSiteGroupParameters = (param, property, value) => {
    const index = siteGroup.parameters
      .map((param) => param.parameterName)
      .indexOf(param.parameterName);
    const updated_param = { ...param, [property]: value };
    const new_parameters = [...siteGroup.parameters];
    new_parameters[index] = updated_param;

    const update = { parameters: new_parameters };
    updateCountries(countryId, siteGroupId, update);

    // Update Site ID Effort based on the number of sites
    const boundToUse = studyPlanType === 1 ? "upperBound" : "lowerBound";
    const siteValue =
      property === boundToUse
        ? value
        : studyPlanType === 1
        ? sites_to_activate.upperBound
        : sites_to_activate.lowerBound;

    if (siteValue === "" || siteValue === null) {
      setManualEffort(null);
    } else {
      const newEffort = getEffortFromSites(siteValue);
      if (newEffort) {
        setManualEffort(newEffort);
      }
    }

    // Enable the save button by setting isCountryUpdate to true
    setIsCountryUpdate(true);
  };

  const handleManualEffortChange = (event) => {
    const newEffort = event.target.value;
    setManualEffort(newEffort);
    handleChange("timeSiteIdSize", newEffort);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit country"
      aria-describedby="edit a country"
      title="Edit Country"
    >
      <Container>
        <Typography>
          The below should be confirmed by the appropriate functional area.
        </Typography>
        <FormControl variant="outlined">
          <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
            <SpecialTextField
              altlabel={studyPlanType !== 1 ? "" : "Lower Bound"}
              value={sites_to_activate.lowerBound}
              onChange={(value) => {
                handleUpdateSiteGroupParameters(
                  sites_to_activate,
                  "lowerBound",
                  value,
                );
              }}
              key={sites_to_activate.parameterName + 1}
              showArrows={false}
            />
            {studyPlanType === 1 && (
              <SpecialTextField
                altlabel="Upper Bound"
                value={sites_to_activate.upperBound}
                onChange={(value) =>
                  handleUpdateSiteGroupParameters(
                    sites_to_activate,
                    "upperBound",
                    value,
                  )
                }
                key={sites_to_activate.parameterName + 2}
                showArrows={false}
              />
            )}
            <p>Number of Activated Sites</p>
            <ToolTip info="Complete the range of sites targeted for each country; ensure the total number of sites across all countries will allow for scenarios that match the total number of sites entered in Overall Parameters; the modeling will return the quickest scenario to last patient enrolled." />
          </div>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="site-id-label">Site ID Effort</InputLabel>
          <Select
            labelId="site-id-label"
            id="select-site-id"
            value={manualEffort || ""}
            onChange={handleManualEffortChange}
            label="Site ID Effort"
          >
            {siteIdData.map(({ value, label, days, sites }) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}{" "}
                {selectedCountry && `(${selectedCountry[days]}; ${sites})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel id="country-startup-label">
            Select Country Start-Up Time Frame
          </InputLabel>
          <Select
            labelId="country-startup-label"
            id="select-country-startup"
            value={currentTimeframe}
            onChange={(event) =>
              handleChange("timeCountryStartUpSize", event.target.value)
            }
            label="Select Country Start-Up Time Frame"
          >
            {timeframes.map(({ value, key, label }) => (
              <MenuItem
                key={key}
                value={value}
              >
                {label}{" "}
                {selectedCountry &&
                  `(${selectedCountry[`timeCountryStartUp${key}Days`]}; ${(
                    selectedCountry[`timeCountryStartUp${key}Days`] / 30.42
                  ).toFixed(2)} months)`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ButtonContainer>
          <SecondaryButton
            onClick={handleCancel}
            disabled={saveLoading}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSave}
            disabled={saveLoading || !isCountryUpdate || isSiteNumberEmpty()}
          >
            {saveLoading ? <CircularProgress size="1em" /> : "Save"}
          </PrimaryButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default EditCountryModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
`;
