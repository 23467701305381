import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

/*
  This component is responsible for rendering the table header of the schedule of events table.
  It uses the material ui table cell component.
  It uses the material ui table row component.
  The parent component must pass the visits of the table.
  The parent component must pass the visits count of the table.
  The parent component must pass the activities percentile of the table.
*/
export default function TableHeader({
  visits,
  visitsCount,
  activitiesPercentile,
}) {
  const renderVisitHeaders = () => {
    return visits.map((visit) => (
      <Cell
        style={{ justifyContent: "flex-start" }}
        key={visit.key || visit.scheduleOfEventsVisitId}
        width={`${(1 / visitsCount) * (100 - activitiesPercentile)}%`}
        variant="header"
      >
        <TextDisplay>{visit.visitCategory || "No-Cat"}</TextDisplay>
        <TextDisplay style={{ border: "none", fontSize: "1.1em" }}>
          {visit.visitName}
        </TextDisplay>
        <TextDisplay>
          {`${visit?.visitIntervalType || "Day"} ${visit.visitStudyDay}`}
        </TextDisplay>
        {visit.visitVarianceType && (
          <TextDisplay>
            {`${visit?.visitVarianceType || "Day"} ${visit.visitVariance}`}
          </TextDisplay>
        )}
      </Cell>
    ));
  };
  return (
    <Row>
      <Cell
        style={{
          justifyContent: "center",
          minWidth: "400px",
          position: "sticky",
          left: 0,
          zIndex: 30,
          borderRight: "1px solid #ccc",
        }}
        width={`${activitiesPercentile}%`}
        variant="header"
      >
        <Typography variant="body2">
          <strong>Activities</strong>
        </Typography>
      </Cell>
      {renderVisitHeaders()}
    </Row>
  );
}

const Row = styled.div`
  position: sticky;
  z-index: 20;
  top: 0;
  width: fit-content;
  min-width: 100%;
  display: flex;
  min-height: fit-content;
  border-bottom: ${(p) => (p.noBorder ? "none" : "1px solid #ccc")};
  &:last-child {
    border: none;
  }
`;

const TextDisplay = styled.span`
  display: block;
  font-size: 0.9em;
  padding: 1px 3px;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  margin-top: 5px;
`;

const Cell = styled.div`
  position: sticky;
  z-index: 20;
  top: 0;
  min-width: 220px;
  background: ${(p) =>
    p.variant === "header" ? "var(--Grey-1)" : "var(--White)"};
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: ${(p) => (p.width ? p.width : "100%")};
  border-right: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  align-self: stretch;
  border-left: ${(p) => (p.leftBorder ? "1px solid var(--Grey-4)" : "none")};
  &:last-child {
    border-right: ${(p) =>
      p.rightBorder ? "1px solid var(--Grey-4)" : "none"};
  }
  border: ${(p) => (p.fullBorder ? "1px solid var(--Grey-4)" : "")};
`;
