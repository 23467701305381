import React from "react";
import styled from "styled-components";

import MuiPaper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { SectionHeader, Tooltip } from "../../../app/components";

export default function DataBox({
  title = "test",
  content = "Hello",
  tooltip,
}) {
  return (
    <Paper
      elevation={0}
      variant="outlined"
    >
      <Header>
        <SectionHeader
          hasStartAdornment={false}
          marginBottom={false}
          useBackground={false}
          endAdornment={tooltip && <Tooltip info={tooltip} />}
        >
          {title}
        </SectionHeader>
      </Header>
      <Content>
        {typeof content === "string" || typeof content === "number" ? (
          <Typography>{content} </Typography>
        ) : (
          content
        )}
      </Content>
    </Paper>
  );
}

const Paper = styled(MuiPaper)``;

const Header = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #f3f4f4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1em;
`;

const Content = styled.div`
  padding: 1em;
  display: flex;
  justify-content: space-around;
`;
