import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import {
  SpecialTextField,
  Tooltip,
  SectionHeader,
} from "../../../../../app/components";
import { useStudyPlan } from "../../../../../app/contexts/study.context";
import SeparateContainer from "../../../../../app/components/separate.Container";

const ScenarioA = ({ values, setValues, setFieldErrors }) => {
  const { studyPlan, updatePlan } = useStudyPlan();
  const {
    enrollmentRate,
    screenFailRate,
    dropOutRate,
    screeningPeriodDuration,
    treatmentDuration,
    followUpDuration,
    parameters,
    milestones,
    pauseEnrollmentAfterPatient = null,
    pauseEnrollmentDays = null,
    cohortType,
    studyPlanTypeDetail,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = studyPlan.parameters;
  const [
    number_patients_enrolled,
    number_patients_treatment,
    number_patients_follow_up,
  ] = milestones.filter(
    ({ milestoneId }) =>
      milestoneId === 144 || milestoneId === 147 || milestoneId === 150,
  );
  const [PauseEnrollment, setPauseEnrollment] = useState({
    pauseEnrollmentAfterPatient: pauseEnrollmentAfterPatient,
    pauseEnrollmentDays: pauseEnrollmentDays,
  });
  const [afterShowEmptyError, setAfterShowEmptyError] = useState(false);
  const [daysShowEmptyError, setDaysShowEmptyError] = useState(false);

  const isNotSequentialCohort = useMemo(
    () => cohortType !== 2 && studyPlanTypeDetail !== "Cohort (D)",
    [cohortType, studyPlanTypeDetail],
  );

  const isValueEmpty = (value) => value === "" || value === null;

  const isRequired = (lowerBound, rate) =>
    isValueEmpty(lowerBound) && isValueEmpty(rate);

  const isNumberPatientsEnrolledOrScreenFaiLRateRequired = isRequired(
    number_patients_enrolled.lowerBound,
    screenFailRate,
  );

  const isPatientsCompletingTreatmentOrDropOutRateRequired = isRequired(
    number_patients_treatment.lowerBound,
    dropOutRate,
  );

  const handlePauseEnrollmentChange = (field, value) => {
    const sanitizedValue = value === null ? null : value;

    setPauseEnrollment({
      ...PauseEnrollment,
      [field]: sanitizedValue,
    });

    if (field === "pauseEnrollmentDays") {
      updatePlan("pauseEnrollmentDays", sanitizedValue);
    }

    if (field === "pauseEnrollmentAfterPatient") {
      updatePlan("pauseEnrollmentAfterPatient", sanitizedValue);
    }
  };

  const handleChangeParam = (param, key, value) => {
    let numericValue;
    if (value === "" || value === null || value === undefined) {
      numericValue = value;
    } else {
      numericValue = isNaN(value) ? value : Number(value);
    }

    const index = parameters.map((param) => param.parameterName).indexOf(key);
    const new_params = [...parameters];

    new_params[index][param] = numericValue;

    updatePlan("parameters", new_params);
  };

  const handleChangeMilestones = (param, key, value) => {
    let numericValue;
    if (value === "" || value === null || value === undefined) {
      numericValue = value;
    } else {
      numericValue = isNaN(value) ? value : Number(value);
    }

    const index = milestones
      .map((milestone) => milestone.milestoneName)
      .indexOf(key);
    const new_milestones = [...milestones];
    new_milestones[index][param] = numericValue;
    updatePlan("milestones", new_milestones);
  };

  const defaultSpecialTextFieldProps = {
    showArrows: false,
  };

  const customValidationDefinition = useMemo(
    () => ({
      enrollmentRate: {
        range: [0.0, 100.0],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: false,
      },
      numberofactivatedsites: {
        range: [1, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      numberofpatientsscreened: {
        range: [1, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      "screeningperiodperpatient(days)": {
        range: [0, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      "treatmentperiodperpatient(days)": {
        range: [0, Infinity],
        validateEmpty: true,
        checkIfInt: true,
        checkIfDec: false,
      },
      "follow-upperiodperpatient(days)": {
        range: [0, Infinity],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      "screenfailrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      "dropoutrate(indecimals)": {
        range: [0.0, 0.99],
        validateEmpty: false,
        checkIfInt: false,
        checkIfDec: true,
      },
      numberofpatientsenrolled: {
        range: [1, subjects_to_screen.lowerBound],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      numbrofpatientscompletingtreatment: {
        range: [
          1,
          number_patients_enrolled.lowerBound || subjects_to_screen.lowerBound,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      numbrofpatientscompletingfollowup: {
        range: [
          1,
          number_patients_treatment.lowerBound ||
            number_patients_enrolled.lowerBound ||
            subjects_to_screen.lowerBound,
        ],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
      pauseEnrollment: {
        range: [1, 9999],
        validateEmpty: false,
        checkIfInt: true,
        checkIfDec: false,
      },
    }),
    [
      subjects_to_screen.lowerBound,
      number_patients_enrolled.lowerBound,
      number_patients_treatment.lowerBound,
    ],
  );

  useEffect(() => {
    const { pauseEnrollmentDays, pauseEnrollmentAfterPatient } =
      PauseEnrollment;

    const isDaysEmpty = !pauseEnrollmentDays;
    const isAfterPatientEmpty = !pauseEnrollmentAfterPatient;

    const pauseEnrollmentAfterShouldShowEmptyError =
      pauseEnrollmentAfterPatient &&
      (pauseEnrollmentDays === "" || isDaysEmpty);

    const pauseEnrollmentDaysShouldDisablePauseEnrollment =
      pauseEnrollmentDays &&
      (pauseEnrollmentAfterPatient === "" || isAfterPatientEmpty);

    setAfterShowEmptyError(pauseEnrollmentDaysShouldDisablePauseEnrollment);
    setDaysShowEmptyError(pauseEnrollmentAfterShouldShowEmptyError);

    if (isDaysEmpty && isAfterPatientEmpty) {
      setFieldErrors((prevState) => {
        const { undefined: _, pauseEnrollmentError, ...rest } = prevState;
        return rest;
      });
    } else if (isDaysEmpty || isAfterPatientEmpty) {
      setFieldErrors((prevState) => ({
        ...prevState,
        pauseEnrollmentError: {
          ...prevState.pauseEnrollmentError,
          errors: ["Can't be empty."],
        },
      }));
    } else {
      setFieldErrors((prevState) => {
        const { undefined: _, pauseEnrollmentError, ...rest } = prevState;
        return rest;
      });
    }
  }, [
    PauseEnrollment.pauseEnrollmentDays,
    PauseEnrollment.pauseEnrollmentAfterPatient,
    PauseEnrollment,
    setFieldErrors,
  ]);

  useEffect(() => {
    if (isNumberPatientsEnrolledOrScreenFaiLRateRequired) {
      setFieldErrors((prevState) => ({
        ...prevState,
        requiredError: {
          ...prevState.requiredError,
          errors: ["Can't be empty."],
        },
      }));
    } else {
      setFieldErrors((prevState) => {
        const { requiredError, ...rest } = prevState;
        return rest;
      });
    }
  }, [screenFailRate, number_patients_enrolled.lowerBound]);

  useEffect(() => {
    if (isPatientsCompletingTreatmentOrDropOutRateRequired) {
      setFieldErrors((prevState) => ({
        ...prevState,
        dropTreatmentRequired: {
          ...prevState.dropTreatmentRequired,
          errors: ["Can't be empty."],
        },
      }));
    } else {
      setFieldErrors((prevState) => {
        const { dropTreatmentRequired, ...rest } = prevState;
        return rest;
      });
    }
  }, [dropOutRate, number_patients_treatment.lowerBound]);

  return (
    <Container>
      <TwoColumnLayout>
        <FlexCol>
          <SeparateContainer>
            <div style={{ marginBottom: "1em" }}>
              <SectionHeader
                hasStartAdornment={false}
                marginBottom={false}
              >
                Overall Parameters:
              </SectionHeader>
            </div>
            <div style={{ padding: "20px" }}>
              <SpecialTextField
                {...defaultSpecialTextFieldProps}
                style={{ marginLeft: "0.5em", marginBlock: "0.5em" }}
                label="Enrollment Rate (p/s/m)"
                value={enrollmentRate}
                onChange={(value) => {
                  updatePlan("enrollmentRate", value);
                }}
                customValidation={customValidationDefinition["enrollmentRate"]}
                endadornment={
                  <Tooltip info="Only complete if you are expecting the same enrollment rate across all countries (if not, skip here and complete at the country level)." />
                }
                setFieldErrors={setFieldErrors}
              />
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  marginLeft: "0.5em",
                  marginBlock: "0.5em",
                }}
              >
                <SpecialTextField
                  {...defaultSpecialTextFieldProps}
                  label={sites_to_activate.parameterDescr}
                  value={sites_to_activate.lowerBound}
                  customValidation={
                    customValidationDefinition["numberofactivatedsites"]
                  }
                  onChange={(value) => {
                    handleChangeParam(
                      "lowerBound",
                      sites_to_activate.parameterName,
                      value,
                    );

                    // trigger formic form update so that we can validate fields
                    setValues({ ...values, updated: new Date().getTime() });
                  }}
                  required
                  key={sites_to_activate.parameterName + 2}
                  endadornment={
                    <Tooltip
                      info={PARAMETER_INFO[sites_to_activate.parameterName]}
                    />
                  }
                  setFieldErrors={setFieldErrors}
                />
              </div>
            </div>
          </SeparateContainer>

          <SeparateContainer>
            <div style={{ marginBottom: "1em" }}>
              <SectionHeader
                hasStartAdornment={false}
                marginBottom={false}
              >
                Per Patient Duration from SOE:
              </SectionHeader>
            </div>
            <div style={{ padding: "20px" }}>
              <SpecialTextField
                {...defaultSpecialTextFieldProps}
                style={{ marginLeft: "0.5em", marginBlock: "0.5em" }}
                label="Screening Period Per Patient (days)"
                value={screeningPeriodDuration}
                customValidation={
                  customValidationDefinition["screeningperiodperpatient(days)"]
                }
                onChange={(value) =>
                  updatePlan("screeningPeriodDuration", value)
                }
                required
                setFieldErrors={setFieldErrors}
              />
              <SpecialTextField
                {...defaultSpecialTextFieldProps}
                style={{ marginLeft: "0.5em", marginBlock: "0.5em" }}
                label="Treatment Period Per Patient (days)"
                value={treatmentDuration}
                customValidation={
                  customValidationDefinition["treatmentperiodperpatient(days)"]
                }
                onChange={(value) => updatePlan("treatmentDuration", value)}
                required
                setFieldErrors={setFieldErrors}
              />
              <SpecialTextField
                {...defaultSpecialTextFieldProps}
                style={{ marginLeft: "0.5em", marginBlock: "0.5em" }}
                label="Follow-Up Period Per Patient (days)"
                value={followUpDuration}
                customValidation={
                  customValidationDefinition["follow-upperiodperpatient(days)"]
                }
                onChange={(value) => updatePlan("followUpDuration", value)}
                endadornment={<Tooltip info="If applicable to the protocol." />}
                setFieldErrors={setFieldErrors}
              />
              <div>
                {false && (
                  <SectionHeader
                    hasStartAdornment={false}
                    marginBottom={false}
                    endAdornment={
                      <Tooltip info="Complete only if a pause in enrollment is expected. For example, if an Interim Analysis is needed after the first 100 patients completes three months of treatment before proceeding, include 100 in the first field and then calculate when screening would resume in the second (consider the treatment time for the 100th patient plus time for the associated data entry and completing and reviewing the interim analysis). Reminder: the second field is when patient screening (NOT enrollment) resumes." />
                    }
                  >
                    Screening/Enrollment Pause (if applicable):
                  </SectionHeader>
                )}
              </div>
            </div>
          </SeparateContainer>

          {false && (
            <>
              {isNotSequentialCohort && (
                <>
                  {" "}
                  <SpecialTextField
                    style={{ marginLeft: "0.5em" }}
                    setFieldErrors={setFieldErrors}
                    label="Pause Enrollment After Patient #"
                    value={PauseEnrollment.pauseEnrollmentAfterPatient}
                    required={
                      PauseEnrollment.pauseEnrollmentDays !== "" &&
                      PauseEnrollment.pauseEnrollmentDays !== null
                    }
                    showCustomError={afterShowEmptyError}
                    customMessageError={"Can't be empty."}
                    showArrows={false}
                    customValidation={
                      customValidationDefinition["pauseEnrollment"]
                    }
                    onChange={(value) => {
                      handlePauseEnrollmentChange(
                        "pauseEnrollmentAfterPatient",
                        value,
                      );
                    }}
                  />
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    style={{ marginLeft: "0.5em" }}
                    label="Resume Screening After (days)"
                    value={PauseEnrollment.pauseEnrollmentDays}
                    showCustomError={daysShowEmptyError}
                    customMessageError={"Can't be empty."}
                    customValidation={
                      customValidationDefinition["pauseEnrollment"]
                    }
                    showArrows={false}
                    required={
                      PauseEnrollment.pauseEnrollmentAfterPatient !== "" &&
                      PauseEnrollment.pauseEnrollmentAfterPatient !== null
                    }
                    onChange={(value) => {
                      handlePauseEnrollmentChange("pauseEnrollmentDays", value);
                    }}
                  />{" "}
                </>
              )}
            </>
          )}
        </FlexCol>
        <FlexCol>
          <SeparateContainer>
            <div style={{ marginBottom: "1em" }}>
              <SectionHeader
                hasStartAdornment={false}
                marginBottom={false}
              >
                Patient Numbers:
              </SectionHeader>
            </div>
            <div style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  marginLeft: "0.5em",
                  marginBlock: "1em",
                }}
              >
                <SpecialTextField
                  {...defaultSpecialTextFieldProps}
                  label={subjects_to_screen.parameterDescr}
                  value={subjects_to_screen.lowerBound}
                  customValidation={
                    customValidationDefinition["numberofpatientsscreened"]
                  }
                  onChange={(value) => {
                    handleChangeParam(
                      "lowerBound",
                      subjects_to_screen.parameterName,
                      value,
                    );
                    // trigger formic form update so that we can validate fields
                    setValues({ ...values, updated: new Date().getTime() });
                  }}
                  required
                  key={subjects_to_screen.parameterName + 2}
                  endadornment={
                    <Tooltip
                      info={PARAMETER_INFO[subjects_to_screen.parameterName]}
                    />
                  }
                  setFieldErrors={setFieldErrors}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  background: "#f3f4f4",
                  padding: "0.5em",
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                  marginBlock: "1em",
                }}
              >
                <div style={{ flex: 1 }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    {...defaultSpecialTextFieldProps}
                    label="Number of Patients Enrolled"
                    value={number_patients_enrolled.lowerBound}
                    disabled={
                      typeof screenFailRate === "number" ||
                      typeof screenFailRate === "string"
                    }
                    required={screenFailRate === null || screenFailRate === ""}
                    showCustomError={
                      isNumberPatientsEnrolledOrScreenFaiLRateRequired
                    }
                    customMessageError={"Can't be empty."}
                    customValidation={
                      customValidationDefinition["numberofpatientsenrolled"]
                    }
                    onChange={(value) => {
                      handleChangeMilestones(
                        "lowerBound",
                        number_patients_enrolled.milestoneName,
                        value,
                      ); // trigger formic form update so that we can validate fields
                      setValues({ ...values, updated: new Date().getTime() });
                    }}
                    key={number_patients_enrolled.milestoneName}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    {...defaultSpecialTextFieldProps}
                    label="Screen Fail Rate (in decimals)"
                    value={screenFailRate}
                    disabled={
                      typeof number_patients_enrolled.lowerBound === "number" ||
                      typeof number_patients_enrolled.lowerBound === "string"
                    }
                    customValidation={
                      customValidationDefinition["screenfailrate(indecimals)"]
                    }
                    onChange={(value) => updatePlan("screenFailRate", value)}
                    required={
                      number_patients_enrolled.lowerBound === "" ||
                      number_patients_enrolled.lowerBound === null
                    }
                    showCustomError={
                      isNumberPatientsEnrolledOrScreenFaiLRateRequired
                    }
                    customMessageError={"Can't be empty."}
                    inputProps={{ step: 0.01 }}
                    min={0.01}
                    endadornment={
                      <Tooltip info="Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here. If a certain number of enrolled patients is required in a specific country, also complete this at the country level." />
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  background: "#f3f4f4",
                  padding: "0.5em",
                  border: "1px solid #f0f0f0",
                  borderRadius: "4px",
                  marginBlock: "1em",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    {...defaultSpecialTextFieldProps}
                    key={number_patients_treatment.milestoneId}
                    label={number_patients_treatment.milestoneLabel}
                    value={number_patients_treatment.lowerBound}
                    customValidation={
                      customValidationDefinition[
                        "numbrofpatientscompletingtreatment"
                      ]
                    }
                    onChange={(value) => {
                      handleChangeMilestones(
                        "lowerBound",
                        number_patients_treatment.milestoneName,
                        value,
                      ); // trigger formic form update so that we can validate fields
                      setValues({ ...values, updated: new Date().getTime() });
                    }}
                    disabled={
                      typeof dropOutRate === "number" ||
                      typeof dropOutRate === "string"
                    }
                    required={dropOutRate === null || dropOutRate === ""}
                    showArrows={false}
                    showCustomError={
                      isPatientsCompletingTreatmentOrDropOutRateRequired
                    }
                    customMessageError={"Can't be empty."}
                  />
                </div>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                  <SpecialTextField
                    setFieldErrors={setFieldErrors}
                    {...defaultSpecialTextFieldProps}
                    label="Drop Out Rate (in decimals)"
                    value={dropOutRate}
                    disabled={
                      typeof number_patients_treatment.lowerBound ===
                        "number" ||
                      typeof number_patients_treatment.lowerBound === "string"
                    }
                    customValidation={
                      customValidationDefinition["dropoutrate(indecimals)"]
                    }
                    onChange={(value) => updatePlan("dropOutRate", value)}
                    showCustomError={
                      isPatientsCompletingTreatmentOrDropOutRateRequired
                    }
                    required={
                      number_patients_treatment.lowerBound === "" ||
                      number_patients_treatment.lowerBound === null
                    }
                    customMessageError={"Can't be empty."}
                    inputProps={{ step: 0.01 }}
                    min={0.01}
                    endadornment={
                      <Tooltip info="Either the Drop Out Rate OR the Number of Patients Completing Treatment must be completed here. If a certain number of completed patients is required in a specific country, also complete this at the country level." />
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "1em",
                  marginLeft: "0.5em",
                  marginBlock: "0.5em",
                }}
              >
                <SpecialTextField
                  setFieldErrors={setFieldErrors}
                  {...defaultSpecialTextFieldProps}
                  key={number_patients_follow_up.milestoneId}
                  label={number_patients_follow_up.milestoneLabel}
                  value={number_patients_follow_up.lowerBound}
                  customValidation={
                    customValidationDefinition[
                      "numbrofpatientscompletingfollowup"
                    ]
                  }
                  onChange={(value) => {
                    handleChangeMilestones(
                      "lowerBound",
                      number_patients_follow_up.milestoneName,
                      value,
                    );
                    // trigger formic form update so that we can validate fields
                    setValues({ ...values, updated: new Date().getTime() });
                  }}
                  endadornment={
                    <Tooltip
                      info={
                        MILESTONE_INFO[number_patients_follow_up.milestoneName]
                      }
                    />
                  }
                />
              </div>
            </div>
          </SeparateContainer>
        </FlexCol>
      </TwoColumnLayout>
    </Container>
  );
};

export default ScenarioA;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const PARAMETER_INFO = {
  SITES_TO_ACTIVATE:
    "Complete with the total number of sites across all countries.",
  SUBJECTS_TO_SCREEN:
    "Include the total number of subjects to screen [Formula = Number of Enrolled Patients/(1 ─ Screen Failure Rate in decimals)]. The simulation will optimize the distribution of patients across the countries. If a specific distribution is required in a country, enter that value at the country level as well.",
};
const MILESTONE_INFO = {
  NUM_SUBJECTS_ENROLLED:
    "If a certain number of enrolled patients is required in a specific country, also complete this at the country level. Either the Screen Fail Rate OR the Number of Patients Enrolled must be completed here or at the country level.",
  NUM_SUBJECTS_COMPLETED: "If applicable to the protocol.",
};

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
  margin-top: 1em;
`;

const FlexCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
